/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { CustomInfosForm, CustomGrid } from '../../../../assets/style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { avatar, selectLogin, nameUser } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink } from 'react-router-dom';
import {
  Stack,
  Typography,
  Grid,
  Card,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import configAxios from '../../../../services/configAxios';
import AvatarImageCropper from 'react-avatar-image-cropper';
import { removeAccents } from '../../../../@core/utils/utilsFunctions';

const Account = (props) => {
  const dispatch = useDispatch();
  const infos = useSelector(selectLogin);
  const [infosUser, setInfosUser] = useState({ ...infos.userLoggedIn[0] });
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [key, setKey] = useState(0);
  const [clickedButton, setClickedButton] = useState('');

  function HandleInputChange(event, validPassword) {
    if (validPassword) {
      setPassword(event.target.value);
      return;
    }
    setInfosUser({ ...infosUser, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    if (clickedButton === 'btnSubmitForm') {
      if (password) {
        infosUser.senha = password;
      } else {
        delete infosUser.senha;
      }
      delete infosUser.logo;
      setClickedButton('');
      configAxios
        .post('/account/updateAccount', { infosUser })
        .then(() => {
          NotificationManager.success('Informações atualizadas com sucesso!', 'Sucesso');
          dispatch(nameUser(infosUser.nome));
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  const apply = (file) => {
    const formData = new FormData();
    const typeFile = file.name.split('.').pop();
    let fileNamekey = file.name.split('.')[0];
    fileNamekey = `${fileNamekey}-${key}`;
    const imgAvatar = new File([file], `${fileNamekey}.${typeFile}`, {
      type: file.type
    });

    const infosAccount = {
      id_type_user: infosUser.id_type_user,
      idUser: infosUser.id
    };

    formData.append('infos', JSON.stringify(infosAccount));
    formData.append('image', imgAvatar, removeAccents(imgAvatar.name));

    HandleUpdateAvatar(formData);
  };

  function HandleUpdateAvatar(formData) {
    configAxios
      .post('account/changeAvatar', formData)
      .then((result) => {
        const file = `${result.data.key}`;
        setKey(key + 1);
        dispatch(avatar(file));
        NotificationManager.success('Avatar atualizado com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar o avatar, tente novamente',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Minha conta">
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
        <Grid container>
          <BreadcrumbsNav {...props} />
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h4" gutterBottom>
              Minha conta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Visualize e edite as suas informações abaixo
            </Typography>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {!isMobile && (
            <Button
              variant="outlined"
              color="error"
              component={RouterLink}
              to="/app/dashboard"
              startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Voltar
            </Button>
          )}
        </Stack>
      </Stack>
      <Card>
        <CustomGrid>
          <form onSubmit={HandleSubmit}>
            <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  name="nome"
                  fullWidth
                  label="Nome completo"
                  variant="outlined"
                  size="small"
                  required
                  value={infosUser.nome || ''}
                  onChange={(event) => HandleInputChange(event)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="email"
                  fullWidth
                  label="Email"
                  required
                  disabled
                  size="small"
                  type="email"
                  variant="outlined"
                  value={infosUser.email || ''}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="senha"
                  fullWidth
                  label="Senha - em branco para não alterar"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => HandleInputChange(event, true)}
                  value={password || ''}
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  name="id_type_user"
                  fullWidth
                  label="Tipo de usuário"
                  variant="outlined"
                  size="small"
                  value={infosUser.desctype || ''}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="operadora"
                  fullWidth
                  label="Operadora/Administradora"
                  disabled
                  size="small"
                  variant="outlined"
                  value={infosUser.nomefantasia || ''}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="status"
                  fullWidth
                  label="Status"
                  size="small"
                  variant="outlined"
                  disabled
                  value={infosUser.ativo === true ? 'Ativo' : 'Inativo'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  background: '#f6f6f6',
                  width: '200px',
                  height: '200px',
                  borderRadius: '10px 100px / 120px'
                }}>
                <AvatarImageCropper
                  text="Selecione o seu avatar"
                  apply={(file) => apply(file)}
                  isBack={false}
                />
              </div>
            </Grid>
            <Stack direction="row" justifyContent="end" sx={{ mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                onClick={() => setClickedButton('btnSubmitForm')}
                sx={{ mr: 2 }}
                startIcon={<Iconify icon="eva:save-fill" />}>
                Atualizar
              </Button>
              <Button
                variant="contained"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </Stack>
          </form>
        </CustomGrid>
      </Card>
    </RenderPage>
  );
};
export default Account;
