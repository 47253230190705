/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin, selectClient } from '../../../../store/login/loginSlice';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import configAxios from 'src/services/configAxios';

const discussionClients = (props) => {
  const URL_AWS_S3 = 'https://mplansistemas-vendaonline.s3.sa-east-1.amazonaws.com';
  const company = useSelector(selectClient);
  const [tpbenef] = useState(props.otherInfos.id_type_client === 1 ? 'fisico' : 'juridico');

  const [input, setInput] = useState();
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [messages, setMessages] = useState([]);
  const [readMessagesCheck, setReadMessagesCheck] = useState(false);
  const [attachFile, setAttachfile] = useState(null);
  useEffect(() => {
    if (props.otherInfos.id) {
      getMessages();
    }
  }, []);

  function getMessages() {
    configAxios
      .get(`/clients/getMessages/${props.otherInfos.id}`)
      .then((result) => {
        setMessages(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as mensagens, tente novamente!',
          'Atenção'
        );
      });
  }

  async function HandleUploadImage(event) {
    if (event) {
      if (event.target.files[0].size > '1000000') {
        NotificationManager.warning(
          'Arquivo muito grande para ser anexado, por favor, verifique!',
          'Atenção'
        );
        return;
      }
      if (
        event.target.files[0].name.split('.').pop() !== 'jpg' &&
        event.target.files[0].name.split('.').pop() !== 'jpeg' &&
        event.target.files[0].name.split('.').pop() !== 'png'
      ) {
        NotificationManager.warning(
          'Somente imagens no formato jpg, jpeg e png são permitidas!',
          'Atenção'
        );
        return;
      }
      setAttachfile(event.target.files[0]);
    }
  }

  function HandleRemoveFile() {
    setAttachfile(null);
  }

  function HandleViewFile(name) {
    const tpbenef = props.otherInfos.id_type_client === 1 ? 'fisico' : 'juridico';
    const id_client = props.otherInfos.id;
    window.open(`${URL_AWS_S3}/${company}/${tpbenef}/${id_client}/discussions/${name}`, '_blank');
  }

  function renderImages(name) {
    const url = `${URL_AWS_S3}/${company}/${tpbenef}/${props.otherInfos.id}/discussions/${name}`;
    return (
      <Box
        sx={{ cursor: 'pointer' }}
        component="img"
        onClick={() => HandleViewFile(name)}
        src={url}
      />
    );
  }

  async function readMessages() {
    const readingUser = infoState.id_type_user === 1 ? 'operadora' : 'corretora';

    if (readingUser === 'operadora') {
      if (readMessagesCheck === true) {
        try {
          await configAxios.post('/clients/readMessages', {
            id_client: props.otherInfos.id,
            readingUser
          });
          props.getCountMessages();
        } catch {
          NotificationManager.error(
            'Tivemos dificuldades para marcar as mensagens como lidas, tente novamente!',
            'Atenção'
          );
        }
      }
    } else {
      try {
        await configAxios.post('/clients/readMessages', {
          id_client: props.otherInfos.id,
          readingUser
        });
        props.getCountMessages();
      } catch {
        NotificationManager.error(
          'Tivemos dificuldades para marcar as mensagens como lidas, tente novamente!',
          'Atenção'
        );
      }
    }
  }

  const HandleSend = async () => {
    if (input.trim() !== '') {
      const formData = new FormData();
      const message = {
        id_client: props.otherInfos.id,
        codclient: props.otherInfos.codclient,
        id_type_client: props.otherInfos.id_type_client,
        mensagem: input.trim(),
        direcao: infoState.id_type_user === 1 ? 'corretora' : 'operadora',
        id_user: infoState.id_type_user === 1 ? props.otherInfos.id_user : null,
        user_id: infoState.id,
        content_file: attachFile ? true : false,
        name_file: attachFile ? attachFile.name : null
      };
      formData.append('file', attachFile);
      formData.append('message', JSON.stringify(message));
      await configAxios
        .post('/clients/addMessage', formData)
        .then((result) => {
          const messages = result.data.messages;
          setInput('');
          setAttachfile(null);
          setMessages(messages);
          props.sendMessage();
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para enviar a mensagem, tente novamente!!',
            'Atenção'
          );
        });
    }
  };

  const HandleInputChange = (event) => {
    setInput(event.target.value);
  };

  const HandleCheckBox = (event) => {
    setReadMessagesCheck(event.target.checked);
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              readMessages();
              setReadMessagesCheck(false);
              setAttachfile(null);
              setInput('');
              onClose();
            }}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => {
          props.setOpenModal(false);
          readMessages();
          setAttachfile(null);
          setInput('');
          setReadMessagesCheck(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth={'sm'}
        open={props.openModal}
        PaperProps={{
          style: {
            height: '700px'
          }
        }}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.setOpenModal(false);
            setReadMessagesCheck(false);
          }}>
          Discussões sobre a venda #{props.otherInfos.codclient}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              {infoState.id_type_user === 1 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={HandleCheckBox}
                      name="checkMessages"
                      checked={readMessagesCheck}
                    />
                  }
                  label={<Typography variant="subtitle2">Marcar mensagens como lidas</Typography>}
                />
              )}
              <div>
                {messages.map((message) => (
                  <Box
                    key={message.id}
                    sx={{
                      display: 'flex',
                      justifyContent: message.direcao === 'corretora' ? 'flex-start' : 'flex-end',
                      mb: 2
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: message.direcao === 'corretora' ? 'row' : 'row-reverse',
                        alignItems: 'center'
                      }}>
                      <Avatar
                        src={URL_AWS_S3 + '/' + message.avatar}
                        sx={{
                          bgcolor: message.direcao === 'corretora' ? 'primary.main' : 'gray.100',
                          width: 65,
                          height: 65
                        }}
                      />
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          ml: message.direcao === 'corretora' ? 1 : 0,
                          mr: !message.direcao === 'corretora' ? 0 : 1,
                          backgroundColor: message.direcao === 'corretora' ? 'light' : 'grey.100',
                          borderRadius:
                            message.direcao === 'corretora'
                              ? '20px 20px 20px 5px'
                              : '20px 20px 5px 20px'
                        }}>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                          {message.mensagem}
                        </Typography>
                        {message.content_file === true && renderImages(message.name_file)}
                        <Typography variant="caption" sx={{ display: 'block', textAlign: 'left' }}>
                          {message.nome}
                        </Typography>
                        <Typography variant="caption" sx={{ display: 'block', textAlign: 'left' }}>
                          {message.date_message.replaceAll('-', ':')}
                        </Typography>
                      </Paper>
                    </Box>
                  </Box>
                ))}
              </div>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          {!isMobile && (
            <Grid container spacing={1} sx={{ p: 1 }}>
              <Grid item xs={9}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  maxRows={4}
                  placeholder="Digite sua mensagem..."
                  variant="outlined"
                  value={input}
                  onChange={HandleInputChange}
                />
              </Grid>
              <Grid item xs={1} sx={{ mt: 0.3, mr: 3, top: 0 }}>
                <Button
                  sx={{ mx: 'auto' }}
                  color="primary"
                  variant="contained"
                  component="label"
                  fullWidth>
                  <AttachmentIcon />
                  <input
                    type="file"
                    hidden
                    accept=".png, .jpg, .jpeg"
                    onChange={HandleUploadImage}
                    onClick={(event) => (event.target.value = null)}
                  />
                </Button>
              </Grid>
              <Grid item xs={1} sx={{ mt: 0.3 }}>
                <Button color="primary" variant="contained" fullWidth onClick={HandleSend}>
                  <SendIcon />
                </Button>
              </Grid>
              {attachFile && (
                <>
                  <Grid item xs={12} md={12} sx={{ ml: 0.5 }}>
                    <Typography variant="caption" color="primary">
                      Arquivo anexado: {attachFile.name}{' '}
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={HandleRemoveFile}>
                        <DeleteIcon />
                      </Button>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {isMobile && (
            <Grid container spacing={1} sx={{ p: 1 }}>
              <div>
                <Grid item xs={7}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Digite sua mensagem..."
                    variant="outlined"
                    value={input}
                    onChange={HandleInputChange}
                  />
                </Grid>
              </div>
              <Grid item xs={1} sx={{ mt: 0.3, mr: 3 }}>
                <Button color="primary" variant="contained" component="label" fullWidth>
                  <AttachmentIcon />
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={HandleUploadImage}
                    onClick={(event) => (event.target.value = null)}
                  />
                </Button>
              </Grid>
              <Grid item xs={2} sx={{ mt: 0.3, ml: 2 }}>
                <Button color="primary" variant="contained" fullWidth onClick={HandleSend}>
                  <SendIcon />
                </Button>
              </Grid>
              {attachFile && (
                <>
                  <Grid item xs={12} md={12} sx={{ ml: 0.5 }}>
                    <Typography variant="caption" color="primary">
                      Arquivo anexado: {attachFile.name}{' '}
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={HandleRemoveFile}>
                        <DeleteIcon />
                      </Button>
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default discussionClients;
