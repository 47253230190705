import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid, Chip } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];

const Products = (props) => {
  const [infosShortage, setInfosShortages] = useState({});
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    getShortages();
  }, []);

  function getShortages() {
    configAxios
      .get('/shortages/getShortages')
      .then((response) => {
        setInfosShortages(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as carências, tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editShortages',
          functionGrid: getShortages,
          otherInfos: item
        }}
      />
    ));
  }

  function ShortageChipPreExistence(items) {
    if (items.data.data.carenciapreexistencia === 'Sim') {
      return (
        <Chip
          color="primary"
          size="small"
          variant="contained"
          label="Sim"
          icon={<Iconify icon="eva:checkmark-circle-2-outline" />}></Chip>
      );
    } else {
      return (
        <Chip
          color="error"
          size="small"
          variant="contained"
          label="Não"
          icon={<Iconify icon="eva:close-circle-outline" />}></Chip>
      );
    }
  }

  function ShortageChipObesidade(items) {
    if (items.data.data.consideragrauobesidade === 'Sim') {
      return (
        <Chip
          color="primary"
          size="small"
          variant="contained"
          label="Sim"
          icon={<Iconify icon="eva:checkmark-circle-2-outline" />}></Chip>
      );
    } else {
      return (
        <Chip
          color="error"
          size="small"
          variant="contained"
          label="Não"
          icon={<Iconify icon="eva:close-circle-outline" />}></Chip>
      );
    }
  }

  function ShortageChipModify(items) {
    if (items.data.data.permitemudanca === 'Sim') {
      return (
        <Chip
          color="primary"
          size="small"
          variant="contained"
          label="Sim"
          icon={<Iconify icon="eva:checkmark-circle-2-outline" />}></Chip>
      );
    } else {
      return (
        <Chip
          color="error"
          size="small"
          variant="contained"
          label="Não"
          icon={<Iconify icon="eva:close-circle-outline" />}></Chip>
      );
    }
  }

  return (
    <RenderPage title="Carências">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de carências
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar as carências
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/addShortages"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Nova
            </Button>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getShortages}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            {(infoState.controlamudancacarencia === 'Não' ||
              !infoState.controlamudancacarencia) && (
              <>
                <DataGrid
                  dataSource={infosShortage}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  noDataText={'Não há carências cadastradas'}>
                  <Column dataField="id" />
                  <Column dataField="codcarencia" caption="Código da carência" />
                  <Column dataField="nomecarencia" caption="Nome da carência" />

                  <Column
                    dataField="Ações"
                    width={100}
                    allowSorting={false}
                    cellRender={(data) => <ActionsButtons data={data} />}
                  />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  {!isMobile && (
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                  )}
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
              </>
            )}
            {infoState.controlamudancacarencia === 'Sim' && (
              <>
                <DataGrid
                  dataSource={infosShortage}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  noDataText={'Não há carências cadastradas'}>
                  <Column dataField="id" />
                  <Column dataField="codcarencia" caption="Código da carência" />
                  <Column dataField="nomecarencia" caption="Nome da carência" />
                  <Column
                    dataField="carenciapreexistencia"
                    caption="Carência de pré existência"
                    cellRender={(data) => <ShortageChipPreExistence data={data} />}
                  />
                  <Column
                    dataField="consideragrauobesidade"
                    caption="Considera grau de obesidade"
                    cellRender={(data) => <ShortageChipObesidade data={data} />}
                  />
                  <Column
                    dataField="permitemudanca"
                    caption="Permite mudanca"
                    cellRender={(data) => <ShortageChipModify data={data} />}
                  />
                  <Column
                    dataField="Ações"
                    width={100}
                    allowSorting={false}
                    cellRender={(data) => <ActionsButtons data={data} />}
                  />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  {!isMobile && (
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                  )}
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
              </>
            )}
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Products;
