/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Tour from 'reactour';
import { Button, Slide, Typography } from '@mui/material';
import pallete from '../../../@core/theme/palette';
import Iconify from '../../../@core/theme/Iconify';

React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let tourConfig = [];

export default function CustomizedDialogs(props) {
  useEffect(() => {
    tourConfig = [];

    if (
      props.entrevisqualificada == 'Sim' &&
      props.tpbenef !== 'resp_jur' &&
      props.tipooperacao !== 'Cartão' &&
      props.tipooperacao !== 'Odonto'
    ) {
      tourConfig.push(
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__iso"]',
          content: (
            <Typography variant="subtitle2">
              Primeiro, você deve escolher o beneficiário do qual deseja visuaizar a declaração de
              saúde, basta clicar sobre o mesmo.
            </Typography>
          )
        },
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__preencher"]',
          content: (
            <Typography variant="subtitle2">
              Depois você deve clicar sobre o botão visualizar.
            </Typography>
          )
        },
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__assinar"]',
          content: (
            <Typography variant="subtitle2">
              Após, utilize o botão para assinar, baixar ou visualizar os documentos assinados!
            </Typography>
          )
        }
      );
      return;
    }
    if (
      props.tpbenef !== 'resp_jur' &&
      props.tipooperacao !== 'Cartão' &&
      props.tipooperacao !== 'Odonto'
    ) {
      tourConfig.push(
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__iso"]',
          content: (
            <Typography variant="subtitle2">
              Primeiro, você deve escolher o beneficiário do qual deseja preencher a declaração,
              basta clicar sobre o mesmo.
            </Typography>
          )
        },
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__preencher"]',
          content: (
            <Typography variant="subtitle2">
              Depois, você deve clicar sobre o botão para realizar a ação desejada: visualizar,
              preencher ou excluir!
            </Typography>
          )
        },
        {
          updateDelay: 1500,
          selector: '[data-tut="reactour__assinar"]',
          content: (
            <Typography variant="subtitle2">
              Após finalizar o preenchimento, você poderá utilizar os botões para assinar, baixar os
              documentos ou visualizar o contrato!
            </Typography>
          )
        }
      );
    } else {
      tourConfig.push({
        updateDelay: 1500,
        selector: '[data-tut="reactour__assinar"]',
        content: (
          <Typography variant="subtitle2">
            Utilize o botão para assinar, baixar ou visualizar os documentos assinados!
          </Typography>
        )
      });
    }
  }, []);

  const accentColor = pallete.primary.main;
  const [isTourOpen, setTourOpen] = React.useState(false);
  const closeTour = () => {
    setTourOpen(false);
  };
  const openTour = () => {
    setTourOpen(true);
  };
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={openTour}
        sx={{ textTransform: 'none' }}
        startIcon={<Iconify icon="eva:question-mark-circle-outline" />}>
        Dúvidas?
      </Button>
      <Tour
        onRequestClose={closeTour}
        disableInteraction={false}
        steps={tourConfig}
        isOpen={isTourOpen}
        className="helper"
        rounded={5}
        accentColor={accentColor}
      />
    </div>
  );
}
