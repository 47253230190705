import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { validateCPF } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';

import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import InputMask from 'react-input-mask';

const addEditUsers = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  const [infosUser, setInfosUser] = useState(state ? { ...state.infosEdit } : {});
  const [infosPrest, setInfosPrest] = useState([]);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    if (state === null && pathNameSplice[2] === '/app/editUsers') [navigation('/app/users')];
    if (!infosUser.id) {
      getPrests();
    }
    if (infoState.id_type_user === 2) {
      infosUser.id_type_user = 2;
      infosUser.id_broker = infoState.id_broker;
    }
  }, []);

  function getPrests() {
    configAxios
      .post('/brokers/listBrokerLabelUsers')
      .then((response) => {
        setInfosPrest(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os corretores, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCPF(event) {
    if (event.target.value) {
      const cpfIsValid = validateCPF(event.target.value);
      if (!cpfIsValid) {
        NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
        setInfosUser({ ...infosUser, user_cpf: '' });
        return;
      }
      setInfosUser({ ...infosUser, [event.target.name]: event.target.value });
    }
  }

  function HandleInputChange(event, prest, validPassword, ChangeTypeUser) {
    if (validPassword) {
      setPassword(event.target.value);
      return;
    }
    if (prest) {
      setInfosUser({ ...infosUser, nomefantasia: event.label, id_broker: event.id });
      return;
    }
    if (ChangeTypeUser) {
      infosUser.gerente = null;
      infosUser.codext = null;
      infosUser.id_profile = null;
    }
    if (event.target.name === 'id_profile') {
      infosUser.codext = null;
    }

    setInfosUser({ ...infosUser, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    if (password) {
      infosUser.senha = password;
    } else {
      delete infosUser.senha;
    }
    const router = infosUser.id ? '/cadusers/updateUser' : '/cadusers/addUser';
    configAxios
      .post(router, { infosUser })
      .then((response) => {
        if (response.status === 202) {
          NotificationManager.warning(
            'Esse email já está sendo utilizado, por favor, escolha outro!',
            'Atenção'
          );
          return;
        }
        const message = infosUser.id
          ? 'Usuário atualizado com sucesso!'
          : 'Usuário inserido com sucesso!';
        NotificationManager.success(message, 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Usuários" id="addEditUsers">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de usuários
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar os usuários
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/users"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosUser.id && (
                    <>
                      <Grid item xs={2} md={1}>
                        <TextField
                          name="iduser"
                          fullWidth
                          label="ID"
                          variant="outlined"
                          size="small"
                          required
                          value={infosUser.id || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={1}>
                        <TextField
                          name="coduser"
                          fullWidth
                          label="Código"
                          variant="outlined"
                          size="small"
                          required
                          value={infosUser.coduser || ''}
                          disabled
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="nome"
                      fullWidth
                      label="Nome completo"
                      variant="outlined"
                      size="small"
                      required
                      value={infosUser.nome || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <InputMask
                      mask="999.999.999-99"
                      value={infosUser.user_cpf || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => validCPF(event)}>
                      {() => (
                        <TextField
                          name="user_cpf"
                          fullWidth
                          label="CPF"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      required
                      disabled={infoState.id_profile === 1 || !infosUser.id ? false : true}
                      size="small"
                      variant="outlined"
                      type="email"
                      value={infosUser.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="senha"
                      fullWidth
                      label={
                        infosUser.id
                          ? 'Senha: em branco para não alterar'
                          : 'Digite a senha do usuário'
                      }
                      type={showPassword ? 'text' : 'password'}
                      onChange={(event) => HandleInputChange(event, false, true)}
                      value={password || ''}
                      size="small"
                      required={infosUser.id ? false : true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        maxLength: 50
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0.1 }}>
                  {infoState.id_type_user === 1 && (
                    <Grid item xs={12} md={2.2} sx={{ fontSize: '13px', mt: 0 }}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Tipo de usuário *
                      </InputLabel>
                      <Select
                        name="id_type_user"
                        fullWidth
                        size="small"
                        required
                        value={infosUser.id_type_user || ''}
                        disabled={infosUser.id ? true : false}
                        onChange={(event) => HandleInputChange(event, false, false, true)}>
                        <MenuItem value={1}>Administradora/Operadora</MenuItem>
                        <MenuItem value={2}>Corretor/Vendedor</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  <Grid item xs={12} md={2}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Perfil *
                    </InputLabel>
                    <Select
                      name="id_profile"
                      fullWidth
                      size="small"
                      required
                      value={infosUser.id_profile || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={1}>Administrador(a)</MenuItem>
                      <MenuItem value={2}>Usuário(a)</MenuItem>
                      {infosUser.id_type_user === 2 && <MenuItem value={3}>Vendedor(a)</MenuItem>}
                      {infoState.entrevisqualificada === 'Sim' && infosUser.id_type_user === 1 && (
                        <MenuItem value={4}>Entrevistador(a)</MenuItem>
                      )}
                    </Select>
                  </Grid>
                  {infoState.id_type_user === 1 && infosUser.id_type_user == 2 && (
                    <Grid item xs={12} md={2.5} sx={{ mt: 2.4 }}>
                      <Autocomplete
                        options={infosPrest}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        isOptionEqualToValue={(option, value) =>
                          value ? option.label === value : option.label === ''
                        }
                        onChange={(event, value) => HandleInputChange(value, true)}
                        value={infosUser.nomefantasia || ''}
                        disabled={infosUser.id ? true : false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Corretora"
                            name="nomefantasia"
                            fullWidth
                            size="small"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {infoState.controlagerente === 'Sim' &&
                    infoState.id_type_user === 1 &&
                    infosUser.id_type_user === 2 &&
                    infosUser.id_profile === 1 && (
                      <Grid item xs={12} md={1.8}>
                        <InputLabel id="label" sx={{ fontSize: '13px' }}>
                          É o gerente na operadora ? *
                        </InputLabel>
                        <Select
                          name="gerente"
                          fullWidth
                          size="small"
                          required
                          value={infosUser.gerente || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'Sim'}>Sim</MenuItem>
                          <MenuItem value={'Não'}>Não</MenuItem>
                        </Select>
                      </Grid>
                    )}
                  {infosUser.gerente === 'Sim' &&
                    infosUser.id_profile === 1 &&
                    infoState.id_type_user === 1 && (
                      <Grid item xs={12} md={1.5} sx={{ mt: 2.2 }}>
                        <TextField
                          name="codext"
                          fullWidth
                          label="Código externo"
                          required
                          size="small"
                          variant="outlined"
                          type="text"
                          value={infosUser.codext || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    )}
                  {infoState.tipointegracao !== 'Não possui' &&
                    infosUser.id_profile === 3 &&
                    infoState.id_type_user === 1 && (
                      <Grid item xs={12} md={1.5} sx={{ mt: 2.2 }}>
                        <TextField
                          name="codext"
                          fullWidth
                          label="Código externo"
                          required
                          size="small"
                          variant="outlined"
                          type="text"
                          value={infosUser.codext || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} md={2}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Status *
                    </InputLabel>
                    <Select
                      name="ativo"
                      fullWidth
                      size="small"
                      required
                      value={infosUser.ativo || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'true'}>Ativo</MenuItem>
                      <MenuItem value={'false'}>Inativo</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  id="btnUsersAddEdit"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosUser.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/users"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditUsers;
