import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Button, Card, Grid, TextField, Chip } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { formatDate, formatCnpjCpf } from '../../../../@core/utils/utilsFunctions';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const allowedPageSizes = [5, 10, 20, 30];

const Simulations = (props) => {
  const [infosSimulations, setInfosSimulations] = useState({});
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infosFilter, setInfosFilter] = useState([]);

  useEffect(() => {
    getSimulations();
  }, []);

  function getSimulations() {
    //tables tb_profiles & tb_type_users
    //profiles 1 = admin, 2 = usuario, 3 = vendedor
    //typeusers 1 = operadora 2 = corretora

    let router;

    if (infoState.id_type_user !== 2) {
      router = `/simulations/listSimulations/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    } else if (
      infoState.id_type_user === 2 &&
      infoState.id_profile !== 1 &&
      infoState.id_profile !== 2
    ) {
      router = `/simulations/listSimulations/${infoState.id_broker}/${infoState.id}/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    } else {
      router = `/simulations/listSimulations/${infoState.id_broker}/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    }

    configAxios
      .get(router)
      .then((response) => {
        setInfosSimulations(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as simulações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputsFilters(event) {
    setInfosFilter({ ...infosFilter, [event.target.name]: event.target.value });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editSimulations',
          functionGrid: getSimulations,
          otherInfos: item
        }}
      />
    ));
  }

  function FormatDate(infos) {
    let dateFormated = formatDate(infos.data.data.datacadastro);
    return dateFormated;
  }

  function FormatCPFCNPJ(infos) {
    let cpfCnpjFormated = formatCnpjCpf(infos.data.data.cpf_cnpj);
    return cpfCnpjFormated;
  }

  function VerifyStatus(infos) {
    if (infos.data.data.status === 'Improvável') {
      return (
        <Chip color="error" size="small" variant="contained" label={infos.data.data.status}></Chip>
      );
    }

    if (infos.data.data.status === 'Pouco provável') {
      return (
        <Chip
          color="warning"
          size="small"
          variant="contained"
          label={infos.data.data.status}></Chip>
      );
    }

    if (infos.data.data.status === 'Provável') {
      return (
        <Chip color="mplan" size="small" variant="contained" label={infos.data.data.status}></Chip>
      );
    }

    if (infos.data.data.status === 'Fechada') {
      return (
        <Chip
          color="success"
          size="small"
          variant="contained"
          label={infos.data.data.status}></Chip>
      );
    }
  }

  return (
    <RenderPage title="Simulações">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de simulações de vendas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar as simulações
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            {infoState.id_type_user === 2 &&
              (infoState.id_profile === 1 || infoState.id_profile === 3) && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/app/addSimulations"
                  startIcon={<Iconify icon="eva:plus-fill" />}>
                  Nova
                </Button>
              )}
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getSimulations}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <CustomInfosForm>
              * Sem filtros, a busca é limitada aos últimos 200 registros
            </CustomInfosForm>
            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateinitial"
                  fullWidth
                  label="Data inicial"
                  variant="outlined"
                  size="small"
                  type="date"
                  // required
                  value={infosFilter.dateinitial || ''}
                  onChange={(event) => HandleInputsFilters(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateend"
                  fullWidth
                  label="Data final"
                  variant="outlined"
                  size="small"
                  type="date"
                  // required
                  value={infosFilter.dateend || ''}
                  onChange={(event) => HandleInputsFilters(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => getSimulations()}
                    startIcon={<Iconify icon="eva:funnel-outline" />}
                    sx={{ mr: 2 }}>
                    Filtrar
                  </Button>
                </Grid>
              )}
              {isMobile && (
                <Grid item xs={12} md={2}>
                  <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 1 }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={() => getSimulations()}
                      startIcon={<Iconify icon="eva:funnel-outline" />}
                      sx={{ mr: 2 }}>
                      Filtrar
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <DataGrid
              dataSource={infosSimulations}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              noDataText={'Não há simulações cadastradas'}>
              <Column dataField="id" />
              <Column dataField="codsimulation" caption="Cód. simulação" width={130} />
              <Column dataField="nome" caption="Nome/razão social" width={200} />
              <Column
                width={150}
                dataField="cpf_cnpj"
                cellRender={(data) => <FormatCPFCNPJ data={data} />}
                caption="Cpf/Cnpj"
              />
              <Column dataField="tpcontrat_client" caption="Tipo de contratação" />
              <Column
                dataField="status"
                caption="Status"
                width={130}
                cellRender={(data) => <VerifyStatus data={data} />}
              />
              {infoState.id_type_user !== 2 && (
                <Column dataField="empresa_vendedor" caption="Corretora" />
              )}
              <Column dataField="nomevend" caption="Vendedor(a)" />

              <Column
                dataField="datacadastro"
                caption="Dt. cadastro"
                cellRender={(data) => <FormatDate data={data} />}
              />
              <Column
                dataField="Ações"
                width={100}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Simulations;
