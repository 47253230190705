import React, { useEffect, useState } from 'react';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { validateCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Box,
  Alert,
  Chip
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import configAxios from '../../../../services/configAxios';
import AddEditAddress from './AddEditAddress';
import LinkPlans from './LinkPlans';
import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const AddEditBroker = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  const [infosBrokers, setInfosBrokers] = useState(state ? { ...state.infosEdit } : {});
  const [infosAddress, setInfosAddress] = useState({});
  const [infosPlans, setInfosPlans] = useState({});
  const [infosAdressEdit, setInfosAddressEdit] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalPlans, setOpenModalPlans] = useState(false);
  const [value, setValue] = useState('1');

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editBrokers') [navigation('/app/brokers')];
    if (infosBrokers.id) {
      getAddress();
      getPlans();
    }
  }, []);

  function getAddress() {
    configAxios
      .get(`/address/listAddress/${infosBrokers.id}`)
      .then((response) => {
        setInfosAddress(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os endereços no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function getPlans() {
    configAxios
      .get(`/brokers/listPlansBroker/${infosBrokers.id}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosBrokers({ ...infosBrokers, cnpj: '' });
        return;
      }
      setInfosBrokers({ ...infosBrokers, [event.target.name]: event.target.value });
    }
  }

  function AddItemsModal(id) {
    if (id) {
      const valuesEdit = infosAddress.filter((element) => element.id === id);
      setInfosAddressEdit(valuesEdit);
    }
    setOpenModal(true);
  }

  function LinktemsModalPlans() {
    setOpenModalPlans(true);
  }

  function handleTabsChange(event, newValue) {
    setValue(newValue);
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editBrokers',
          deleteRouter: '/address/deleteAddress',
          deleteItem: 'o endereço',
          functionGrid: getAddress,
          otherInfos: item,
          functionModal: AddItemsModal
        }}
      />
    ));
  }

  function ActionsButtonsPlans(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/brokers/deletePlansBrokers',
          deleteItem: 'o plano da corretora',
          functionGrid: getPlans,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  function HandleInputChange(event) {
    setInfosBrokers({ ...infosBrokers, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosBrokers.id ? '/brokers/updateBroker' : '/brokers/addBroker';
    configAxios
      .post(router, infosBrokers)
      .then((response) => {
        const message = infosBrokers.id
          ? 'Corretora atualizada com sucesso!'
          : 'Corretora inserida com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfosBrokers({
          ...infosBrokers,
          id: response.data.id
        });
        const nav = infosBrokers.id
          ? `/app/editBrokers/${infosBrokers.id}`
          : `/app/editBrokers/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosBrokers,
              id: infosBrokers.id ? infosBrokers.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function RenderStatus(event) {
    if (event.data.data.status === 'ATIVO') {
      return <Chip color="success" size="small" variant="contained" label="Ativo"></Chip>;
    }
    return <Chip color="error" size="small" variant="contained" label="Inativo"></Chip>;
  }

  return (
    <RenderPage title="Corretoras" id="brokers">
      <>
        <AddEditAddress
          openModal={openModal}
          setOpenModal={setOpenModal}
          functionGrid={getAddress}
          infosItemEdit={infosAdressEdit}
          otherInfos={infosBrokers}
        />
        <LinkPlans
          openModal={openModalPlans}
          setOpenModal={setOpenModalPlans}
          functionGrid={getPlans}
          otherInfos={infosBrokers}
        />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de corretoras
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as corretoras
              </Typography>
            </Grid>
            {!Object.keys(infosAddress).length && (
              <Alert severity="warning">
                Nenhum endereço cadastrado para a corretora, considere cadastrar!
              </Alert>
            )}
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/brokers"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosBrokers.id && (
                    <Grid item xs={2} md={1}>
                      <TextField
                        name="idproduct"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosBrokers.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="razaosocial"
                      fullWidth
                      label="Razão social"
                      variant="outlined"
                      size="small"
                      required
                      value={infosBrokers.razaosocial || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="nomefantasia"
                      fullWidth
                      label="Nome fantasia"
                      variant="outlined"
                      size="small"
                      required
                      value={infosBrokers.nomefantasia || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputMask
                      mask="99.999.999/0009-99"
                      value={infosBrokers.cnpj || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => validCNPJ(event)}>
                      {() => (
                        <TextField
                          name="cnpj"
                          fullWidth
                          label="CNPJ"
                          required
                          variant="outlined"
                          size="small"
                          // inputProps={{ maxLength: 14 }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={isMobile ? { mt: 0 } : { mt: 2 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      type="email"
                      required
                      value={infosBrokers.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Regime tributário *
                    </InputLabel>
                    <Select
                      name="id_regime"
                      fullWidth
                      size="small"
                      required
                      value={infosBrokers.id_regime || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1'}>Micro empreendedor Individual</MenuItem>
                      <MenuItem value={'2'}>Simples Nacional</MenuItem>
                      <MenuItem value={'3'}>Lucro Presumido</MenuItem>
                      <MenuItem value={'4'}>Lucro Real</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      name="ativo"
                      fullWidth
                      size="small"
                      required
                      value={infosBrokers.ativo || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'true'}>Ativo</MenuItem>
                      <MenuItem value={'false'}>Inativo</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={isMobile ? { mt: 0.5 } : { mt: 2 }}>
                  <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 3 : 4}>
                    <TextField
                      name="diapgto"
                      fullWidth
                      label="Dia do pagamento"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                      type="number"
                      variant="outlined"
                      size="small"
                      required
                      value={infosBrokers.diapgto || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  {infoState.tipointegracao !== 'Não possui' && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        name="codext"
                        fullWidth
                        label="Código externo"
                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
                        type="number"
                        variant="outlined"
                        size="small"
                        required
                        value={infosBrokers.codext || ''}
                        onChange={(event) => HandleInputChange(event)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 6 : 8}>
                    <TextField
                      name="obs"
                      fullWidth
                      label="Observações sobre a corretora"
                      variant="outlined"
                      size="small"
                      value={infosBrokers.obs || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
              </Box>
              {infosBrokers.id && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={value} variant="scrollable" scrollButtons="auto">
                    <Box sx={{ mt: 3, p: 1 }}>
                      <TabList onChange={handleTabsChange} aria-label="BrokersTabs">
                        <Tab label="Endereços" value="1" />
                        <Tab label="Planos" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm>Adicionar e/ou remover endereços</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={AddItemsModal}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getAddress}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={AddItemsModal}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosAddress}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há endereços cadastrados'}>
                        {/* <Column dataField="id" width={70} /> */}
                        <Column dataField="rua" />
                        <Column dataField="numero" caption="Número" width={100} />
                        <Column dataField="bairro" />
                        <Column dataField="cidade" />
                        <Column dataField="estado" width={100} />
                        <Column dataField="cep" width={100} />
                        <Column dataField="desctype" caption="Tipo endereço" width={150} />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtons data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>Adicionar e/ou remover planos</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinktemsModalPlans}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getPlans}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinktemsModalPlans}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosPlans}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há planos vinculados'}>
                        {/* <Column dataField="id" width={70} /> */}
                        <Column dataField="codplano" caption="Código do plano" />
                        <Column dataField="descplano" caption="Descrição do plano" />
                        <Column dataField="tpcontrat" caption="Tipo de contratação" />
                        <Column
                          dataField="status"
                          caption="Status"
                          cellRender={(data) => <RenderStatus data={data} />}
                        />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsPlans data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosBrokers.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/brokers"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default AddEditBroker;
