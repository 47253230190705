/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomGrid, BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import '../../../../assets/style/privacy.css';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin, selectTypeClient, selectClient } from '../../../../store/login/loginSlice';
import {
  Stack,
  Typography,
  Button,
  Card,
  Grid,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Box,
  Snackbar,
  Fade,
  Link
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AddEditDeclarationHealth from './AddDeclarationHealth';
import DocumentsForSignature from './DocumentsForSignature';

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DownloadDocumentsSigning from './DownloadDocumentsSigning';
import Demo from '../../../components/multiplesComponents/Demo';
import ShowDocuments from '../../../components/multiplesComponents/ShowDocuments';

const allowedPageSizes = [5, 10, 20, 30];

const signature = (props) => {
  const URL_AWS_S3 = 'https://mplansistemas-vendaonline.s3.sa-east-1.amazonaws.com';
  const video = `${URL_AWS_S3}/homolog/assets/video/instrucao.mp4`;

  const [rowSelected, setRowSelected] = useState();
  const [openModalDeclarationHealth, setOpenModalDeclarationHealth] = useState(false);
  const [showDeclaration, setShowDeclaration] = useState(false);

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalInstructions, setModalInstructions] = useState(false);

  const infoStateTemp = useSelector(selectLogin);
  const typeClient = useSelector(selectTypeClient);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const company = useSelector(selectClient);

  const [infosClients, setInfosClients] = useState([{ statusvenda: '' }]);
  const infosPJ = useRef({ statusvenda: '', nomefantasia: '', codclient: '' });
  const infosPF = useRef({ statusvenda: '', nome: '', codclient: '' });

  const [infosQuestions, setInfosQuestions] = useState([]);
  const [responseQuestions, setResponseQuestions] = useState([]);
  const [url] = useState(URL_AWS_S3);
  const [urlDocument, setUrlDocument] = useState(null);

  const [documentsSigning, setDocumentsSigning] = useState({});
  const [OpenModalShowDocument, setOpenModalShowDocument] = useState(false);

  const [buttonSign, setButtonSign] = useState(false);
  const [buttonDownload, setButtonDownload] = useState(false);

  const [privacyOpen, setPrivacyOpen] = useState(false);

  const user = useSelector((state) => state.login.authenticatedUser);
  let tpbenef = useSelector((state) => state.login.type);

  const blockStatus = ['Aberta', 'Análise', 'Rejeitada', 'Negada'];

  const navigation = useNavigate();
  const client = useSelector((state) => state.login.clientLogin);
  const routerLogout = `/portal/${client}`;

  /**info
   * id_type_client === 1 - Físico
   * id_type_client === 2 - Júridico
   *
   */

  useEffect(() => {
    getInfosclients();
    getInfosQuestions();
    getDocumentsSigning();

    setPrivacyOpen(true);
  }, []);

  function rejectAccess() {
    navigation(routerLogout);
  }

  async function getInfosclients() {
    const router =
      infoState.id_type_client === 1
        ? `/clients/getInfosSignaturePF/${infoState.id_client}`
        : `/clients/getInfosSignaturePJ/${infoState.id_client}/${infoState.id}/${typeClient}`;

    await configAxios
      .get(router)
      .then((result) => {
        if (blockStatus.includes(result.data.client.statusvenda)) {
          rejectAccess();
        }
        if (infoState.id_type_client === 1) {
          infosPF.current = result.data.client;
          setInfosClients(result.data.health);

          if (result.data.client.statusvenda !== 'Aprovada') {
            setButtonSign(true);
          }

          if (result.data.client.statusvenda === 'Aprovada') {
            setButtonDownload(true);
          }
        }
        if (infoState.id_type_client === 2) {
          infosPJ.current = result.data.client;
          setInfosClients(result.data.health);

          if (infoState.tpcontrat === '2-COLETIVO EMPRESARIAL') {
            if (result.data.client.statusvenda !== 'Aprovada') {
              setButtonSign(true);
            }

            if (result.data.client.statusvenda === 'Aprovada') {
              setButtonDownload(true);
            }
          }
        }
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getInfosQuestions() {
    await configAxios
      .get('/questions/listQuestions')
      .then((response) => {
        setInfosQuestions(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function RenderHealth(infos) {
    if (infos.data.data.completedhealthdeclaration) {
      return 'Sim';
    }
    return 'Não';
  }

  function selectedRow(event) {
    setRowSelected(event.selectedRowsData[0]);
  }

  function addDeclarationHealthModal() {
    if (!rowSelected) {
      NotificationManager.warning(
        'Selecione o beneficiário para preencher a declaração de saúde!',
        'Atenção'
      );
      return;
    }
    if (rowSelected.completedhealthdeclaration === true) {
      NotificationManager.warning(
        'Declaração já preenchida, selecione o botão visualizar para visualizar a declaração!',
        'Atenção'
      );
      return;
    }
    setOpenModalDeclarationHealth(true);
  }

  function showDeclarationHealthModal() {
    if (!rowSelected) {
      NotificationManager.warning(
        'Selecione o beneficiário para visualizar a declaração de saúde!',
        'Atenção'
      );
      return;
    }

    if (rowSelected.completedhealthdeclaration === false) {
      NotificationManager.warning(
        'Declaração ainda não preenchida, selecione o botão preencher para preenche-la!',
        'Atenção'
      );
      return;
    }
    getResponsesQuestions();
    setShowDeclaration(true);
    setOpenModalDeclarationHealth(true);
  }

  function getDocumentsSigning() {
    const tpcontrat = user.userLoggedIn[0].tpcontrat;
    let client = {};

    if (user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp) {
      client = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        tpcontrat: tpcontrat,
        tpbenef: tpbenef,
        included_resp: 'S'
      };
    } else {
      client = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        tpcontrat: tpcontrat,
        tpbenef: tpbenef,
        included_resp: 'N'
      };
    }

    configAxios
      .post(`/signing/getDocumentsSigning`, client)
      .then((result) => {
        setDocumentsSigning(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os documentos assinados, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getResponsesQuestions() {
    const flag = rowSelected.flag ? 'tb_clients' : 'tb_clients_dep';
    const id = flag === 'tb_clients' ? infoState.id_client : rowSelected.id;

    const router =
      infoState.id_type_client === 1
        ? `/questions/getResponsesQuestionsPF/${id}/${flag}`
        : `/questions/getResponsesQuestionsPJ/${rowSelected.id}`;

    await configAxios
      .get(router)
      .then((response) => {
        setResponseQuestions(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as informações, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function openContractForS3() {
    let contract;

    contract =
      infosPF.current.codcont !== null ? infosPF.current.codcont : infoState.codcontrato_plano;

    if (infoState.id_type_client === 2) {
      contract =
        infosPJ.current.codcont !== null ? infosPJ.current.codcont : infoState.codcontrato_plano;
    }
    setUrlDocument(`${url}/${company}/assets/contracts/${contract}.pdf`);

    setOpenModalShowDocument(true);
  }

  function ButtonShowContract() {
    if (infoState.tpcontrat === '1-INDIVIDUAL/FAMILIAR') {
      return (
        <>
          {(typeClient === 'resp_jur' || infoState.titbenef === 'S') &&
            infoState.tipo === 'Complexo' && (
              <Button
                variant="contained"
                color="ligth"
                onClick={() => {
                  openContractForS3();
                }}
                sx={
                  isMobile
                    ? { mb: 1, textTransform: 'none', mr: 2, mt: 1 }
                    : { ml: 1, textTransform: 'none' }
                }
                startIcon={<Iconify icon="eva:file-text-outline" />}>
                Visualizar contrato
              </Button>
            )}
        </>
      );
    }

    if (infoState.tpcontrat === '2-COLETIVO EMPRESARIAL' && infoState.tipooperacao !== 'Odonto') {
      return (
        <>
          {(typeClient === 'resp_jur' ||
            user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp) &&
            infoState.tipo === 'Complexo' && (
              <Button
                variant="contained"
                color="ligth"
                onClick={() => {
                  openContractForS3();
                }}
                sx={
                  isMobile
                    ? { mb: 1, textTransform: 'none', mr: 1, mt: 1 }
                    : { ml: 1, textTransform: 'none' }
                }
                startIcon={<Iconify icon="eva:file-text-outline" />}>
                Visualizar contrato
              </Button>
            )}
        </>
      );
    }
  }

  function ButtonsActions() {
    if (
      (infoState.tpbenef === 'Titular' ||
        infoState.titbenef === 'S' ||
        infoState.tpbenef === 'Titular menor') &&
      (infosPJ.current.statusvenda === 'Aguardando cliente' ||
        infosPF.current.statusvenda === 'Aguardando cliente') &&
      !documentsSigning.length
    ) {
      return (
        <>
          {infoState.entrevisqualificada === 'Não' && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={addDeclarationHealthModal}
                sx={{ textTransform: 'none' }}
                startIcon={<Iconify icon="eva:edit-fill" />}>
                Preencher
              </Button>
              <Button
                onClick={() => {
                  openModalDeleteDeclarationHealth(true);
                }}
                color="error"
                variant="contained"
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:trash-2-outline" />}>
                Excluir
              </Button>
            </>
          )}
        </>
      );
    }
  }

  function RenderStatus() {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        sx={isMobile ? { mb: 2, mt: 2 } : { mt: 2, ml: 1 }}>
        {infoState.id_type_client === 1 && (
          <Grid container>
            <Grid item xs={12}>
              <CustomInfosForm>Código: #{infosPF.current.codclient}</CustomInfosForm>
            </Grid>
            <Grid item xs={12}>
              <CustomInfosForm>Status: {infosPF.current.statusvenda}</CustomInfosForm>
            </Grid>
          </Grid>
        )}
        {infoState.id_type_client === 2 && (
          <Grid container>
            <Grid item xs={12}>
              <CustomInfosForm>Código da venda: #{infosPJ.current.codclient}</CustomInfosForm>
            </Grid>
            <Grid item xs={12}>
              <CustomInfosForm>Status atual: {infosPJ.current.statusvenda}</CustomInfosForm>
            </Grid>
          </Grid>
        )}
      </Stack>
    );
  }

  function RenderButttonShowDeclarationOfHealth() {
    if (typeClient !== 'resp_jur' && infoState.tipooperacao !== 'Odonto') {
      return (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ mb: 2 }}
            data-tut="reactour__preencher">
            <Button
              variant="contained"
              color="primary"
              onClick={showDeclarationHealthModal}
              sx={{ mr: 1, textTransform: 'none' }}
              startIcon={<MonitorHeartIcon />}>
              Visualizar
            </Button>
            <ButtonsActions />
          </Stack>
        </>
      );
    }
  }

  function openModalDeleteDeclarationHealth() {
    if (!rowSelected) {
      NotificationManager.warning(
        'Selecione o beneficiário para excluir a declaração de saúde!',
        'Atenção'
      );
      return;
    }

    if (rowSelected.completedhealthdeclaration === false) {
      NotificationManager.warning(
        'Declaração ainda não preenchida, selecione o botão preencher para preenche-la!',
        'Atenção'
      );
      return;
    }

    setModalDeleteOpen(true);
  }

  function HandleDeleteDeclarationHealth() {
    const flag = rowSelected.flag ? 'tb_clients' : 'tb_clients_dep';

    configAxios
      .delete(`/questions/deleteResponseDeclarationHealth/${rowSelected.id}/${flag}`)
      .then(() => {
        setModalDeleteOpen(false);
        getInfosclients(infoState);

        NotificationManager.success('Declaração de saúde excluída com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para excluir a declaração de saúde, tente novamente!',
          'Atenção'
        );
      });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPrivacyOpen(false);
  };
  const action = (
    <>
      <Button color="primary" variant="contained" size="small" onClick={handleClose}>
        Prosseguir
      </Button>
    </>
  );
  const link = (
    <>
      Ao prosseguir com a assinatura dos documentos, você aceita os termos expressos e estipulados e
      dá como verdadeira a sua assinatura eletrônica, conforme RN (resolução normativa) Nº 413, cujo
      texto pode ser conferido no diário oficial da união
      <Link
        href="https://www.in.gov.br/web/dou/-/resolucao-normativa-rn-n-413-de-11-de-novembro-de-2016-24504005"
        underline="none"
        target="_blank"
        rel="noopener">
        {' '}
        clicando aqui.
      </Link>
    </>
  );

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <RenderPage title="Assinaturas e declarações de saúde">
      <>
        <Snackbar
          open={privacyOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={20000}
          TransitionComponent={Fade}
          onClose={handleClose}
          message={link}
          action={action}
          ContentProps={{
            sx: {
              background: '#FFFFFF',
              color: 'black',
              borderRadius: '10px'
            }
          }}
        />
        <div>
          <BootstrapDialog
            onClose={() => {
              setModalInstructions(false);
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'md'}
            PaperProps={{
              sx: { borderRadius: 0 }
            }}
            open={modalInstructions}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setModalInstructions(false);
              }}>
              Instruções para preenchimento e assinatura de documentos
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <video src={video} width="100%" height="100%" controls></video>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setModalInstructions(false);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Fechar
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
        <div>
          <BootstrapDialog
            onClose={() => {
              setModalDeleteOpen(false);
              getInfosclients(infoState);
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            PaperProps={{
              sx: { borderRadius: 0 }
            }}
            open={modalDeleteOpen}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setModalDeleteOpen(false);
              }}>
              Excluir declaração de saúde
            </BootstrapDialogTitle>

            <DialogContent dividers>
              <Typography variant="subtitle2">
                Deseja realmente excluir a declaração de saúde?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  HandleDeleteDeclarationHealth();
                }}
                color="success"
                startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
                Sim
              </Button>
              <Button
                onClick={() => {
                  setModalDeleteOpen(false);
                  getInfosclients(infoState);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Não
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
        <AddEditDeclarationHealth
          openModal={openModalDeclarationHealth}
          setOpenModal={setOpenModalDeclarationHealth}
          showDeclaration={showDeclaration}
          setShowDeclaration={setShowDeclaration}
          responseQuestions={responseQuestions}
          functionGrid={getInfosclients}
          infosAskQuestions={infosQuestions}
          rowSelected={rowSelected}
          controlaMudancaCarencia={infoState.controlamudancacarencia}
          statusvenda={
            infosPJ.current.statusvenda !== '' && typeClient !== 'resp_jur'
              ? infosPJ.current.statusvenda
              : infosClients[0].statusvenda
          }
        />
        <ShowDocuments
          openModal={OpenModalShowDocument}
          url={urlDocument}
          setOpenModal={setOpenModalShowDocument}
        />

        {isMobile && (
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Stack>
              <Demo
                tpbenef={tpbenef}
                tipooperacao={infoState.tipooperacao}
                entrevisqualificada={infoState.entrevisqualificada}
              />
            </Stack>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" justifyContent="start" mb={0}>
          <Grid container>
            <BreadcrumbsNav {...props} sx={{ mr: 2 }} />
            {!isMobile && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                  {infoState.tipooperacao !== 'Cartão' && infoState.tipooperacao !== 'Odonto'
                    ? 'Assinaturas e declarações de saúde'
                    : 'Assinatura de documentos'}
                </Typography>
              </Grid>
            )}
            {isMobile &&
              infoState.tipooperacao !== 'Cartão' &&
              infoState.tipooperacao !== 'Odonto' && (
                <>
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Utilize as opções abaixo para preencher as declarações e assinar todos os
                      documentos
                    </Typography>
                  </Grid>
                  {/* <Alert severity="info">
                  Prezado(a), não se esqueça de preencher as declarações de saúde e assinar todos os
                  documentos!
                </Alert> */}
                </>
              )}
            {isMobile &&
              (infoState.tipooperacao === 'Cartão' || infoState.tipooperacao === 'Odonto') && (
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Utilize as opções abaixo para assinar todos os documentos
                  </Typography>
                </Grid>
              )}

            {!isMobile &&
              infoState.tipooperacao !== 'Cartão' &&
              infoState.tipooperacao !== 'Odonto' && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Utilize as opções abaixo para preencher as declarações de saúde e assinar os
                      documentos
                    </Typography>
                  </Grid>
                  {/* <Alert severity="info">
                  Prezado(a), não se esqueça de preencher as declarações de saúde e assinar todos os
                  documentos!
                </Alert> */}
                </>
              )}
            {!isMobile &&
              (infoState.tipooperacao === 'Cartão' || infoState.tipooperacao === 'Odonto') && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Utilize as opções abaixo assinar todos os documentos!
                    </Typography>
                  </Grid>
                </>
              )}
          </Grid>
          {!isMobile && (
            <>
              <Stack>
                <Demo
                  tpbenef={tpbenef}
                  tipooperacao={infoState.tipooperacao}
                  entrevisqualificada={infoState.entrevisqualificada}
                />
              </Stack>
            </>
          )}
        </Stack>
        <Stack
          data-tut="reactour__assinar"
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={isMobile ? { mb: 2, mt: 2 } : { mb: 2 }}>
          <ButtonShowContract />
          {buttonSign === true && (
            <DocumentsForSignature
              functionGrid={getInfosclients}
              getDocumentsSigning={getDocumentsSigning}
              infosClients={infosClients}
              infosPJ={infosPJ}
              infosPF={infosPF}
            />
          )}
          {buttonDownload === true && (
            <DownloadDocumentsSigning
              infosClients={infosClients}
              infosPJ={infosPJ}
              tipo={
                infosPJ.current.tpcontrat_client
                  ? infosPJ.current.tpcontrat_client
                  : infosPF.current.tpcontrat_client
              }
            />
          )}
        </Stack>
        <Card>
          <CustomGrid data-tut="reactour__iso">
            <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
              <RenderStatus />
              {infoState.tipooperacao !== 'Cartão' && infoState.tipooperacao !== 'Odonto' && (
                <RenderButttonShowDeclarationOfHealth />
              )}
              <DataGrid
                dataSource={infosClients}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onSelectionChanged={selectedRow}>
                <Selection mode="single" />
                <Column dataField="nome" caption="Nome" />
                {!isMobile && <Column dataField="cpf_cnpj" caption="Cpf" />}
                {!isMobile && <Column dataField="tpbenef" caption="Tipo de beneficiário" />}
                {!isMobile && <Column dataField="email" caption="Email" />}
                {infoState.tipooperacao !== 'Cartão' && infoState.tipooperacao !== 'Odonto' && (
                  <Column
                    dataField="completedhealthdeclaration"
                    caption="Declaração preenchida"
                    cellRender={(data) => <RenderHealth data={data} />}
                  />
                )}
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={30} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            </Box>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default signature;
