import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useMemo } from 'react';

import imglogo from '../../assets/images/multiples/mplan_reduz.png';

function Copyright() {
  const img = imglogo;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" src={`${isLogoTemp}`} sx={{ mr: -0.5, mt: 0.5 }} />;
  const logo = isLogo;

  return (
    <Typography component={'span'} variant="body2" color="text.secondary" align="left">
      <Stack>
        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
          {logo} Mplan Sistemas © {new Date().getFullYear()} - Todos os direitos reservados
        </Typography>
      </Stack>
    </Typography>
  );
}

function CopyrightMobile() {
  const img = imglogo;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" src={`${isLogoTemp}`} />;
  const logo = isLogo;

  return (
    <Typography component={'span'} variant="body2" color="text.secondary" align="center">
      <Stack>
        <Typography variant="subtitle3" sx={{ color: 'text.primary' }}>
          {logo} Mplan Sistemas © {new Date().getFullYear()} - Todos os direitos reservados
        </Typography>
      </Stack>
    </Typography>
  );
}

export default function Footer() {
  return (
    <>
      {isMobile && <CopyrightMobile />}
      {!isMobile && <Copyright />}
    </>
  );
}
