import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { validateCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Box,
  Chip
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import LinkPlans from './LinkPlans';
import LinkBrokers from './LinkBrokers';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const AddEditAssociation = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infosAssociation, setInfosAssociation] = useState(state ? { ...state.infosEdit } : {});
  const [infosPlans, setInfosPlans] = useState({});
  const [infosBrokers, setInfosBrokers] = useState({});
  const [openModalPlans, setOpenModalPlans] = useState(false);
  const [openModalBrokers, setOpenModalBrokers] = useState(false);
  const [value, setValue] = useState('1');

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editAssociation')
      [navigation('/app/associations')];
    if (infosAssociation.id) {
      getPlans();
      getBrokers();
    }
  }, []);

  function getPlans() {
    configAxios
      .get(`/association/listPlansAssociation/${infosAssociation.id}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function getBrokers() {
    configAxios
      .get(`/association/listAssociationBrokers/${infosAssociation.id}`)
      .then((response) => {
        setInfosBrokers(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function LinktemsModalPlans() {
    setOpenModalPlans(true);
  }

  function LinkBrokersModalPlans() {
    setOpenModalBrokers(true);
  }

  function ActionsButtonsPlans(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/association/deletePlansAssociation',
          deleteItem: 'o plano da associação',
          functionGrid: getPlans,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  function ActionsButtonsBrokers(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/association/deleteBrokerAssociation',
          deleteItem: 'a corretora da associação',
          functionGrid: getBrokers,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  async function HandleInputCep(event) {
    if (event.target.value) {
      if (validCep(event.target.value)) {
        let infosCep;
        infosCep = await viaCep(event.target.value);
        if (infosCep) {
          HandleInputChange({ ...infosCep }, true);
          return;
        }
        NotificationManager.danger(
          'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
      }
    }
  }

  function HandleInputChange(event, cep) {
    if (cep) {
      setInfosAssociation({ ...infosAssociation, ...event });
      return;
    }
    setInfosAssociation({ ...infosAssociation, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosAssociation.id
      ? '/association/updateAssociation'
      : '/association/addAssociation';
    configAxios
      .post(router, infosAssociation)
      .then((response) => {
        const message = infosAssociation.id
          ? 'Associação atualizada com sucesso!'
          : 'Associação inserida com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfosAssociation({
          ...infosAssociation,
          id: response.data.id
        });
        const nav = infosAssociation.id
          ? `/app/editAssociations/${infosAssociation.id}`
          : `/app/editAssociations/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosAssociation,
              id: infosAssociation.id ? infosAssociation.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosAssociation({ ...infosAssociation, cnpj: '' });
        return;
      }
      setInfosAssociation({ ...infosAssociation, [event.target.name]: event.target.value });
    }
  }

  function handleTabsChange(event, newValue) {
    setValue(newValue);
  }

  function TextObservation(data) {
    if (data.data.value) {
      return data.data.value;
    }
    return '-';
  }

  function RenderStatus(event) {
    if (event.data.data.status === 'ATIVO') {
      return <Chip color="success" size="small" variant="contained" label="Ativo"></Chip>;
    }
    return <Chip color="error" size="small" variant="contained" label="Inativo"></Chip>;
  }

  return (
    <RenderPage title="Associações">
      <>
        <LinkPlans
          openModal={openModalPlans}
          setOpenModal={setOpenModalPlans}
          functionGrid={getPlans}
          otherInfos={infosAssociation}
        />
        <LinkBrokers
          openModal={openModalBrokers}
          setOpenModal={setOpenModalBrokers}
          functionGrid={getBrokers}
          otherInfos={infosAssociation}
        />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de associações
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as associações
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/associations"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosAssociation.id && (
                    <Grid item xs={2} md={1}>
                      <TextField
                        name="id"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAssociation.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="razaosocial"
                      fullWidth
                      label="Razão social"
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.razaosocial || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="nomefantasia"
                      fullWidth
                      label="Nome fantasia"
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.nomefantasia || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 2 : 3}>
                    <InputMask
                      mask="99.999.999/0009-99"
                      value={infosAssociation.cnpj || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => validCNPJ(event)}>
                      {() => (
                        <TextField
                          name="cnpj"
                          fullWidth
                          label="CNPJ"
                          required
                          variant="outlined"
                          size="small"
                          // inputProps={{ maxLength: 14 }}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  {infoState.tipointegracao !== 'Não possui' && (
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="codext"
                        fullWidth
                        label="Código externo"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAssociation.codext || ''}
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} sx={isMobile ? { mt: 0 } : { mt: 2 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      type="email"
                      required
                      value={infosAssociation.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Regime tributário *
                    </InputLabel>
                    <Select
                      name="id_regime"
                      fullWidth
                      size="small"
                      required
                      value={infosAssociation.id_regime || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1'}>Micro empreendedor Individual</MenuItem>
                      <MenuItem value={'2'}>Simples Nacional</MenuItem>
                      <MenuItem value={'3'}>Lucro Presumido</MenuItem>
                      <MenuItem value={'4'}>Lucro Real</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      name="ativo"
                      fullWidth
                      size="small"
                      required
                      value={infosAssociation.ativo || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'true'}>Ativo</MenuItem>
                      <MenuItem value={'false'}>Inativo</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={2}>
                    <InputMask
                      mask="99999-999"
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => HandleInputCep(event)}
                      value={infosAssociation.cep || ''}>
                      {() => (
                        <TextField
                          name="cep"
                          fullWidth
                          label="CEP"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="rua"
                      fullWidth
                      label="Rua"
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.rua || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="numero"
                      fullWidth
                      label="Número"
                      value={infosAssociation.numero || ''}
                      required
                      size="small"
                      variant="outlined"
                      type="number"
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="complemento"
                      fullWidth
                      label="Complemento"
                      size="small"
                      value={infosAssociation.complemento || ''}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="bairro"
                      fullWidth
                      label="Bairro"
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.bairro || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="cidade"
                      fullWidth
                      label="Cidade"
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      value={infosAssociation.cidade || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="estado"
                      fullWidth
                      label="Estado"
                      disabled
                      required
                      size="small"
                      variant="outlined"
                      value={infosAssociation.estado || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="obs"
                      fullWidth
                      label="Observações sobre a associação"
                      variant="outlined"
                      size="small"
                      value={infosAssociation.obs || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
              </Box>

              {infosAssociation.id && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={value}>
                    <Box sx={{ mt: 3, p: 1 }}>
                      <TabList onChange={handleTabsChange} aria-label="AssociationTabs">
                        <Tab label="Corretoras" value="1" />
                        <Tab label="Planos" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>Adicionar e/ou remover corretoras</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkBrokersModalPlans}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Nova
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getBrokers}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkBrokersModalPlans}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosBrokers}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há corretoras vinculadas'}>
                        <Column dataField="id" width={70} />
                        <Column dataField="razaosocial" caption="Razão social" />
                        <Column dataField="cnpj" caption="Cnpj" />
                        <Column
                          dataField="obs"
                          caption="Observações"
                          cellRender={(data) => <TextObservation data={data} />}
                        />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsBrokers data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item xs={12}>
                              <CustomInfosForm>Adicionar e/ou remover planos</CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinktemsModalPlans}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getPlans}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinktemsModalPlans}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosPlans}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há planos vinculados'}>
                        <Column dataField="id" width={70} />
                        <Column dataField="codplano" caption="Código do plano" />
                        <Column dataField="descplano" caption="Descrição do plano" />
                        <Column dataField="tpcontrat" caption="Tipo de contratação" />
                        <Column
                          dataField="status"
                          caption="Status"
                          cellRender={(data) => <RenderStatus data={data} />}
                        />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsPlans data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosAssociation.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/association"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};
export default AddEditAssociation;
